const DB = {
  nicl: {
    name: "Nicola",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00521959,
        date: "28-01-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00021758,
        date: "28-01-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00253807,
        date: "28-02-2022",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00016666,
        date: "28-02-2022",
      },
    ],
  },
  ksia: {
    name: "Kasia",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00447619,
        date: "23-02-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00014378,
        date: "23-02-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00217391,
        date: "23-02-2022",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00014792,
        date: "23-02-2022",
      },
    ],
  },
  mikolaj: {
    name: "Mikołaj",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00150141,
        date: "12-12-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00012272,
        date: "12-12-2021",
      },
    ],
  },
  vika: {
    name: "Vika",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00150141,
        date: "12-12-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00012272,
        date: "12-12-2021",
      },
    ],
  },
  ptrk: {
    name: "Patryk",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00392958,
        date: "23-03-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00011678,
        date: "23-03-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00221238,
        date: "12-09-2021",
      },
      {
        name: "ADA",
        id: "cardano",
        value: 2.8,
        date: "12-09-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00185185,
        date: "23-03-2022",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00013227,
        date: "23-03-2022",
      },
    ],
  },
  jastrzebie: {
    name: "Jastrzebscy",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00315577,
        date: "5-04-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00011596,
        date: "5-04-2021",
      },
    ],
  },
  mamarenia: {
    name: "Mama Renia",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00168351,
        date: "9-05-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00011578,
        date: "9-05-2021",
      },
    ],
  },
  chimek: {
    name: "Chimek",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00477959,
        date: "29-04-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00024741,
        date: "29-04-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.01480124,
        date: "26-06-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00083219,
        date: "26-06-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.002,
        date: "30-04-2022",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00014705,
        date: "30-04-2022",
      },
    ],
  },
  igor: {
    name: "Igor",
    coins: [
      {
        name: "ADA",
        id: "cardano",
        value: 19.9,
        date: "08-03-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.002035,
        date: "29-04-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00014078,
        date: "12-08-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.0029065,
        date: "12-08-2022",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00023148,
        date: "12-08-2022",
      },
    ],
  },
  mamagosia: {
    name: "Gosia",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00194099,
        date: "21-08-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00013034,
        date: "21-08-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.0029065,
        date: "21-08-2022",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00023148,
        date: "21-08-2022",
      },
    ],
  },
  czarek: {
    name: "Czarek",
    coins: [
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00301204,
        date: "21-08-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00019379,
        date: "09-07-2021",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.003378,
        date: "21-07-2022",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.0002284,
        date: "21-07-2022",
      },
    ],
  },
  tatapiotrek: {
    name: "Piotrek",
    coins: [
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.06,
        date: "1-08-2017",
      },
      {
        name: "BNB",
        id: "binancecoin",
        value: 1.1,
        date: "22-02-2021",
      },
      {
        name: "DOGE",
        id: "binance-peg-dogecoin",
        value: 200,
        date: "26-04-2021",
      },
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.011111,
        date: "1-08-2022",
      },
    ],
  },
  mati: {
    name: "Mateusz",
    coins: [
      {
        name: "BTC",
        id: "bitcoin",
        value: 0.00015111,
        date: "25-09-2017",
      },
      {
        name: "ETH",
        id: "ethereum",
        value: 0.00221238,
        date: "25-09-2021",
      },
    ],
  },
};

export default DB;
