<template>
  <div class="wallet">
    <h1>Twoje krypto: {{ name }}</h1>
    <ul>
      <li v-for="(coin, i) in mapedCoin" :key="i">
        <span class="number">{{ i + 1 }}.</span>
        <span class="">{{ coin.date }}</span>
        <span class="">{{ coin.name }}</span>
        <span class="">{{ coin.value }}</span>
        <span class="price">{{ formatPrice(coin.price) }}</span>
      </li>
      <li>
        <span class="number"></span>
        <span></span>
        <span></span>
        <span></span>
        <span class="price var_bold">{{ formatPrice(sum) }}</span>
      </li>
    </ul>
    <!-- <h2>Summary</h2> -->
    <!-- {{ prices }} -->
    <!-- {{ coinsSum }} -->
    <!-- <ul>
      <li v-for="(coin, i) in coinsSum" :key="i">
        <span class="number">{{ coin }}.</span>
      </li>
    </ul> -->
  </div>
</template>

<script>
import DB from "@/assets/db";
import axios from "axios";
import currency from "currency.js";

const PLN = value => currency(value, { symbol: " zł", separator: " ", decimal: ",", pattern: '#!' });

export default {
  name: "Wallet",
  props: ["id"],
  data() {
    return {
      db: DB,
      prices: [],
      coinsValue: [],
      coinsSum: [],
      mapedCoin: [],
			allowedCoins: ["bitcoin","ethereum","cardano","binancecoin","binance-peg-dogecoin"]
    };
  },
  computed: {
    userData() {
      return this.db[this.id];
    },
    coins() {
			const coins = [];
			const allCoins = Object.values(this.db);
			// console.log(allCoins);

			allCoins.forEach(user => {
				const userCoins = user.coins;
				// console.log(userCoins);
				userCoins.forEach(coin => {
					console.log(coin);
					const existedRecord = coins.find(v => v.id === coin.id);
					console.log(existedRecord);
					if (!existedRecord) {
						coins.push(coin);
					} else {
						existedRecord['value'] += coin.value;
					}
				});
			});

      return coins;
    },
    name() {
      return 'All Coins';
    },
    sum() {
      return this.mapedCoin.length ? this.mapedCoin.reduce((acc, curr) => acc + curr.price, 0) : 0;
    },
  },
  mounted() {
    this.fetchData();
    // this.updateCoinsSum();
  },
  methods: {
		formatPrice(price) {
			return PLN(price).format();
		},
    getPrice(value, id) {
      return this.prices.length ? value * this.prices[id].pln : "---";
    },
    updateCoinsSum() {
      const ret = [];
      this.coins.forEach((coin) => {
        if (Object.prototype.hasOwnProperty.call(ret, coin.name)) {
          ret[coin.name] += coin.value;
        } else {
          ret[coin.name] = coin.value;
        }
      });

      this.coinsSum = ret;
    },
    updateCoinsValue() {},
    async fetchData() {
			const coinsId = this.allowedCoins.join();
      try {
        let res = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${coinsId}&vs_currencies=pln`
        );
        this.prices = res.data;

        this.mapedCoin = this.coins.map((v) => {
          return { ...v, price: Math.round(v.value * this.prices[v.id].pln * 100) / 100 };
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wallet {
  ul {
    list-style: none;
    display: flex;
    width: 90%;
    margin: 0 auto;
    max-width: 800px;
    flex-wrap: wrap;

    li {
      flex: 0 0 100%;
      padding: 10px 0px;
      background: rgba(#18a558, 0.5);
      display: flex;

      &:nth-child(2n + 2) {
        background: #a3ebb1;
      }

      span {
        padding: 0 10px;
        flex: 0 0 20%;
        &.number {
          flex: 0 0 20px;
        }

        &.price {
          text-align: right;

					&.var_bold {
						font-weight: bold;
					}

        }
      }
    }
  }
}
</style>
